import type { JobLocation, Person, Picture, TextCollection } from "@/types/contentful-api";

export enum Gender {
	male = "male",
	female = "female",
}

export interface PersonProps {
	person: Person;
}

export enum ContactFormKeys {
	consent = "consent",
	email = "email",
	firstName = "firstName",
	lastName = "lastName",
	location = "location",
	newsletterConsent = "newsletterConsent",
	phoneNumber = "phone",
	resume = "resume",
}

// This is different as the values map to keys in the Greenhouse API
export enum GreenhouseFormKeys {
	consent = "consent",
	coverLetter = "cover_letter",
	resume = "resume",
	email = "email",
	firstName = "first_name",
	lastName = "last_name",
	location = "location",
	newsletterConsent = "newsletterConsent",
	phone = "phone",
}

export enum FormType {
	AGENT = "AGENT",
	EXPOSE_REQUEST = "EXPOSE_REQUEST",
	JOB_APPLICATION = "JOB_APPLICATION",
	NEWSLETTER = "NEWSLETTER",
	SELLER = "SELLER",
	BUYER = "BUYER",
	SELECTED = "SELECTED",
	VALUATION = "VALUATION",
	VALUATION_FUNNEL = "VALUATION_FUNNEL",
	POSTCODE_VALUATION_FUNNEL = "POSTCODE_VALUATION_FUNNEL",
	CONTACT_NO_SNACKBAR = "CONTACT_NO_SNACKBAR",
	PARTNER = "PARTNER",
}

export interface ContactFormProps {
	contactDetails: Person | Picture;
	additionalParameters?: Record<string, unknown>;
	type: FormType;
	textCollection: TextCollection;
}

export interface GreenhouseApplicationFormProps {
	forwardedRef?: React.Ref<HTMLDivElement>;
	textCollection: TextCollection;

	/**
	 * "jobLocations"
	 * These come from Contentful
	 * Specifically from KeyVisual entries
	 * They are a way to use the application form outside of a job details
	 * page by assigning 1 or more locations to the widget based form, which
	 * will be displayed as a select for the user if there are more than 1
	 */
	jobLocations?: JobLocation[];

	/**
	 * "isAgentApplicationForm"
	 * Enables further field(s) and required logic
	 */
	isAgentApplicationForm?: boolean;

	/**
	 * Greenhouse job id
	 */
	jobId?: number;
}

export interface FormProps {
	[ContactFormKeys.firstName]: string | null;
	[ContactFormKeys.lastName]: string | null;
	[ContactFormKeys.email]: string | null;
	[ContactFormKeys.phoneNumber]?: string;
	[ContactFormKeys.consent]: boolean;
	[ContactFormKeys.newsletterConsent]: boolean;
}

/**
 * The base set of form fields for Greenhouse applications
 * This is not the definitive list as custom questions are loaded on the fly
 * for a job if they have been set in Greenhouse.
 */
export interface GreenhouseFormProps {
	[GreenhouseFormKeys.firstName]: string | null;
	[GreenhouseFormKeys.location]: string | null;
	[GreenhouseFormKeys.lastName]: string | null;
	[GreenhouseFormKeys.email]: string | null;
	[GreenhouseFormKeys.phone]: string;
	[GreenhouseFormKeys.resume]: string;
	[GreenhouseFormKeys.coverLetter]?: string;
	[GreenhouseFormKeys.consent]: boolean;
	[GreenhouseFormKeys.newsletterConsent]: boolean;
}

export type GreenhouseQuestion = {
	required: boolean;
	label: string;
	description: string;
	fields: [
		{
			name: string;
			type: string;
			values?: Array<{ label: string; value: string }>;
		}
	];
};

export interface JobApplicationFormWithSnackbarProps {
	isAgentApplicationForm?: boolean;
	jobLocations?: JobLocation[];
	textCollection: TextCollection;
}
